body,
html {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  min-height: 600px;
}

* {
  overflow: hidden;
}

/* body *::-webkit-scrollbar { */
/* width: 0.7em; */
/* background: #29252e; */
/* } */
body *::-webkit-scrollbar-thumb {
  background: #908796;
  height: 30px;
}

body *::-webkit-scrollbar-track-piece {
  display: none;
}

#root {
  /* max-width: 400px; */
  min-height: 600px;
  height: 100%;
  /* max-height: 600px; */
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
